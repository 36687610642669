// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary                : #7940FF;
$secondary              : #29D3BF;
$accent                 : #C9970E;

$dark                   : #212121;
$gray                   : #B9B9B9;

$positive               : #21BA45;
$negative               : #C10015;
$info                   : #31CCEC;
$warning                : #cb9d33;

// Customs - Font
$font                   : 'Palanquin', sans-serif;

// Customs
$background             : #F8F9FF;
$background2            : #FFFFFF;
$light                  : #F3F3F3;
$subtext                : lighten($dark, 25%);
$positive-bg            : #F1FFF4; /* TODO: Get dark page vals*/
$warning-bg             : #FFFEF1;
$negative-bg            : #FFF1F1;
$icon-color             : #303030;
$editor-border-color    : rgba(0, 0, 0, 0.12); 

// Customs - Dark-Page
$dark-page              : #121212;
$primary-dark-page      : $primary;
$primary-dark-page-font : lighten($primary, 10%);
$secondary-dark-page    : $secondary;
$secondary-dark-page-font: lighten($secondary, 10%);
$primary-bg-dark       : mix($dark, $primary, 75%);
$positive-bg-dark       : mix($dark, $positive, 75%);
$warning-bg-dark        : mix($dark, $warning, 75%);
$negative-bg-dark       : mix($dark, $negative, 75%);

//custom values
$mixVal: 90%;
$primarize: invert(65%) sepia(83%) saturate(500%) hue-rotate(206deg) brightness(85%) contrast(2); // Transform svg icons into primary color
$secondarize: invert(65%) sepia(83%) saturate(382%) hue-rotate(121deg) brightness(92%) contrast(92%); // Transform svg icons into secondary color
$darkmodize: brightness(0) invert(1); // Transform svg icons for darkmode to white
